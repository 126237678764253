import { useMemo } from 'react'

import { useAccessToken, decodeAccessToken } from '@b-stock/bstock-next'

/**
 * Returns an account ID that FE should submit when identifying the account
 * against which or as which actions should be taken. Returns null if the this
 * ID can not be determined (e.g. for unauth'd users)
 *
 * Currently returns the accountId identified in the access token. Will need
 * to account for path params which identify an acting account in the future.
 */
const useAccountId = (): string | null => {
  const accessToken = useAccessToken()

  return useMemo(() => {
    if (!accessToken) {
      return null
    }

    try {
      return decodeAccessToken(accessToken).accountId
    } catch {
      return null
    }
  }, [accessToken])
}

export default useAccountId
